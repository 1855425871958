@import '../../../../scss/theme-bootstrap';

.site-footer__wrap {
  max-width: none;
  padding: 0;
  div.sticky-offer {
    visibility: hidden;
  }
}

.sitewide-footer-formatter {
  position: relative;
  background-color: $color-core-black;
  clear: both;
  color: $color-white;
  margin: 0 auto;
  padding-bottom: 35px;
  text-align: $ldirection;
  @include breakpoint($landscape-up) {
    padding-bottom: 100px;
    width: 100%;
  }
  &__wrapper {
    @include typography-body-text;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }
  &__logo {
    width: 100%;
    margin: 40px 0;
    height: auto;
    @include breakpoint($landscape-up) {
      margin: 100px 0 160px;
      height: 5vw;
    }
    .svgicon {
      width: 100%;
      height: 100%;
      fill: $color-white;
    }
  }
  &__top {
    flex: 0 100%;
    @include breakpoint($landscape-up) {
      flex: 0 43%;
      margin-top: 10px;
    }
  }
  &__bottom {
    &-wrapper {
      @include typography-body-text;
      color: $color-core-dark-gray;
      line-height: 1.3;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      width: 100%;
      flex-direction: column-reverse;
      margin-top: 40px;
      @include breakpoint($landscape-up) {
        flex-direction: row;
        margin-top: 0;
      }
    }
  }
  .site-footer__email {
    &-message {
      @include breakpoint($landscape-up) {
        margin-bottom: 10px;
      }
      .newsletter-signup {
        a {
          color: $color-white-op50;
          display: inline;
          text-decoration: underline;
        }
      }
    }
    &-signup {
      .site-footer-email-signup {
        .site-email-signup {
          &__form {
            display: flex;
            flex-wrap: wrap;
          }
          &__error {
            width: 100%;
            color: $color-core-red;
            margin-top: 20px;
            @include breakpoint($landscape-up) {
              margin-top: 10px;
            }
          }
          &__success {
            color: $color-core-dark-gray;
            max-width: 80%;
            margin-top: 30px;
            @include breakpoint($landscape-up) {
              max-width: 50%;
              margin-top: 0;
            }
          }
        }
        input {
          &[type='email'],
          &[type='phone'] {
            @include typography-body-text;
            border: solid 1px $color-line;
            border-width: 0 0 1px;
            background: transparent;
            width: 100%;
            max-width: 100%;
            padding: 10px 0 30px;
            margin: 40px 0 0;
            -webkit-appearance: none;
            border-radius: 0;
            @include breakpoint($landscape-up) {
              margin: 54px 0 0;
            }
            &::placeholder {
              @include typography-headline--m;
              color: $color-white-op50;
              text-transform: inherit;
            }
          }
          &[type='button'],
          &[type='submit'] {
            @include typography-body-text;
            background: transparent;
            text-transform: initial;
            color: $color-core-dark-gray;
          }
        }
        &__gdpr-label {
          margin-top: 15px;
        }
        &__terms-conditions {
          margin: 10px 0 15px;
          position: relative;
          label:first-of-type {
            @include typography-annotation-text;
            color: $color-white-op50;
            a {
              color: $color-white-op50;
              text-decoration: underline;
              display: inline-block;
            }
          }
          input[type='checkbox'] {
            & ~ .label {
              padding-#{$ldirection}: 25px;
              -webkit-appearance: none;
              &:before {
                background: $color-white;
                position: absolute;
                #{$ldirection}: 0;
                top: 2px;
                margin: 0;
                font-size: 15px;
              }
            }
            &:focus ~ .label:before {
              @include outline;
            }
            &:checked ~ .label:before {
              color: $color-black;
            }
          }
        }
        &__email_promotions {
          margin: 0 15px;
        }
        &__submit {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 40px;
          @include breakpoint($landscape-up) {
            margin-top: 44px;
          }
          label {
            width: 15px;
            order: 2;
            svg {
              width: 15px;
              height: 15px;
              fill: $color-core-dark-gray;
            }
          }
        }
      }
    }
  }
  &__content {
    margin-bottom: 0;
    margin-top: 10px;
    width: 100%;
    @include breakpoint($landscape-up) {
      flex: 0 50%;
      margin-bottom: 75px;
    }
  }
  &__links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include breakpoint($landscape-up) {
      flex-wrap: nowrap;
    }
    &-col {
      font-family: $bbtext-regular;
      display: block;
      margin: 0;
      padding: 0 0 30px;
      text-decoration: none;
      width: 100%;
      word-wrap: break-word;
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 20px 0 0);
        min-width: calc(100% / 3);
        flex: 1 1 auto;
        width: auto;
        &:nth-child(4n) {
          min-width: 0;
        }
      }
      .footer-links {
        &__title {
          line-height: 1.2;
          margin-bottom: 10px;
        }
        &__link {
          color: $color-white-op50;
          margin-bottom: 10px;
          .link {
            @include typography-body-text;
            color: $color-white-op50;
            display: inline-block;
            border: 0;
            padding: 0;
            &:hover {
              color: $color-white;
            }
          }
        }
      }
    }
  }
  &__social {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    width: 100%;
    @include breakpoint($landscape-up) {
      margin-top: 50px;
    }
    &-title {
      width: 100%;
      margin-bottom: 15px;
      line-height: 1.2;
    }
    &-link {
      display: inline-block;
      margin-#{$rdirection}: 15px;
      &:last-of-type {
        margin-#{$rdirection}: 0;
      }
      svg {
        fill: $color-white;
        width: 20px;
        height: 20px;
      }
    }
  }
  &__trustmark {
    margin-top: 27px;
    &-link img {
      max-width: 115px;
    }
  }
  &__country-chooser {
    width: 100%;
    margin-top: 40px;
    margin-#{$rdirection}: 0;
    @include breakpoint($landscape-up) {
      width: 43%;
      margin-top: 0;
      margin-#{$rdirection}: 7%;
    }
    .site-footer-country-chooser {
      display: flex;
      flex-wrap: wrap;
      @include breakpoint($landscape-up) {
        flex-wrap: nowrap;
      }
    }
    .selectBox {
      @include swap_direction(padding, 25px 50px 25px 30px);
      text-transform: capitalize;
      margin: 0;
      height: auto;
      font-size: 15px;
      color: $color-white;
      background: $color-core-black;
      border: solid 1px $color-white-op50;
      line-height: 1.3;
      &:hover {
        color: $color-white;
      }
      &-arrow {
        background: transparent;
        display: flex;
        align-items: center;
        width: 10px;
        line-height: 1;
        padding-#{$rdirection}: 40px;
        #{$rdirection}: 0;
        &::before {
          position: absolute;
          display: block;
          #{$ldirection}: 0;
          top: 0;
          width: 10px;
          content: '';
          height: 100%;
          background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') no-repeat;
          background-size: 10px;
          background-position: center;
          filter: grayscale(1) invert(1);
        }
      }
      &-menuShowing .selectBox-arrow {
        @include transform(scaleY(-1));
      }
    }
    select {
      @include swap_direction(padding, 25px 50px 25px 30px);
      min-width: 100%;
      text-transform: capitalize;
      margin: 0;
      height: auto;
      font-size: 15px;
      color: $color-white;
      border: solid 1px $color-white-op50;
      line-height: 1.3;
      background: $color-core-black;
      &:hover {
        color: $color-white;
      }
    }
    .country-select {
      position: relative;
      margin-bottom: 20px;
      width: 100%;
      margin-#{$rdirection}: 0;
      @include breakpoint($landscape-up) {
        width: 50%;
        margin-#{$rdirection}: 20px;
      }
      &::after {
        position: absolute;
        #{$rdirection}: 30px;
        top: 0;
        width: 10px;
        content: '';
        height: 100%;
        background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') no-repeat;
        background-size: 10px;
        background-position: center;
        filter: grayscale(1) invert(1);
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      &:focus-within::after {
        @include transform(scaleY(-1));
      }
    }
    .language-select {
      margin: 0;
      border: solid 1px $color-white-op50;
      position: absolute;
      width: calc(100% + 2px);
      #{$ldirection}: -1px;
      bottom: 100%;
      background: $color-core-black;
      padding: 0 20px;
      display: none;
      visibility: hidden;
      opacity: 0;
      &__default {
        color: $color-white;
        position: relative;
        width: 100%;
        text-align: #{$ldirection};
        &::after {
          position: absolute;
          display: block;
          #{$rdirection}: -20px;
          top: 0;
          width: 10px;
          content: '';
          height: 100%;
          background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') no-repeat;
          background-size: 10px;
          background-position: center;
          filter: grayscale(1) invert(1);
        }
      }
      &__wrapper {
        @include swap_direction(padding, 25px 50px 25px 30px);
        @include typography-body-text;
        background-color: $color-core-black;
        width: 100%;
        border: solid 1px $color-white-op50;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        position: relative;
        text-transform: capitalize;
        height: auto;
        min-height: 70px;
        @include breakpoint($landscape-up) {
          width: 50%;
        }
        &.active {
          & > span::after {
            @include transform(scaleY(-1));
          }
          .language-select {
            display: block;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      &__item {
        display: block;
        width: 100%;
        border: 0;
        float: none;
        padding: 0;
        margin: 25px 0;
        text-align: #{$ldirection};
      }
      &__link {
        line-height: 1.1;
        background: $color-core-black;
        color: $color-core-dark-gray;
        margin: 15px 0;
        font-size: 15px;
        &:hover {
          color: $color-white;
        }
        &--selected {
          text-decoration: underline;
          color: $color-white;
        }
      }
    }
    .locale-select {
      margin: 20px 0;
      &__link {
        color: $color-core-dark-gray;
        text-decoration: none;
        &--selected {
          color: $color-white;
          text-decoration: underline;
        }
        &:hover {
          color: $color-white;
        }
      }
      &__item {
        border: 0;
      }
    }
  }
  &__terms {
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 40%;
    }
  }
  &__term-link {
    @include typography-annotation-text;
    display: block;
    color: $color-white-op50;
    margin-#{$rdirection}: 15px;
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
    &:last-of-type {
      margin-#{$rdirection}: 0;
    }
    a {
      color: $color-white-op50;
      text-decoration: underline;
      &:hover {
        color: $color-white;
      }
    }
    button {
      @include typography-annotation-text;
      display: block;
      color: $color-white-op50;
      text-decoration: underline;
      text-transform: none;
      padding: 0;
      display: block;
      height: auto;
      background: transparent;
      &:hover {
        color: $color-white;
        text-decoration: underline;
      }
    }
  }
  &__copyright {
    @include typography-annotation-text;
    line-height: 1.33;
    color: $color-white-op50;
    margin-bottom: 15px;
    @include breakpoint($landscape-up) {
      margin-bottom: 0;
    }
  }
  &__accessibility-icon {
    width: 100%;
    text-align: center;
    order: -1;
    margin: 15px 0;
    @include breakpoint($landscape-up) {
      width: 10%;
      text-align: $rdirection;
      order: 0;
      margin: 0;
    }
    a {
      @include typography-annotation-text;
      color: $color-white-op50;
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: center;
      @include breakpoint($landscape-up) {
        gap: 10px;
        justify-content: flex-end;
      }
      &:hover {
        color: $color-white;
      }
    }
    span {
      text-align: $ldirection;
    }
    img {
      max-width: 80px;
      @include breakpoint($landscape-up) {
        max-width: 52px;
      }
    }
  }
  .pg-checkout &,
  body[id*='account'] & {
    padding: 0 20px;
  }
}

.country-select__selectbox-selectBox-dropdown-menu,
.language-select__selectbox-selectBox-dropdown-menu {
  border: solid 1px $color-core-dark-gray;
  background: $color-white;
  max-height: 312px;
  padding: 0 0 20px;
  scrollbar-width: none;
  scrollbar-color: $color-core-dark-gray $color-white;
  @include breakpoint($landscape-up) {
    background: $color-core-black;
    max-height: 330px;
    scrollbar-width: auto;
    scrollbar-color: $color-core-dark-gray $color-core-black;
  }
  li:first-of-type a {
    color: $color-core-black;
    margin-bottom: 30px;
    overflow: visible;
    position: relative;
    @include breakpoint($landscape-up) {
      color: $color-core-dark-gray;
      margin-bottom: 0;
      overflow: hidden;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      background: $color-line;
      height: 1px;
      width: 90%;
      #{$ldirection}: 5%;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  .touchevents & {
    position: sticky;
    top: auto !important;
    bottom: 0 !important;
    width: 100% !important;
    #{$ldirection}: 0 !important;
    max-height: none;
    height: 50vh;
  }
  &::-webkit-scrollbar {
    width: 25px;
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &::-webkit-scrollbar-track {
    background: $color-white;
    @include breakpoint($landscape-up) {
      background: $color-core-black;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-core-dark-gray;
    border-radius: 0;
    border: 20px solid $color-white;
    border-width: 0 20px 0 0;
    @include breakpoint($landscape-up) {
      border: 20px solid $color-core-black;
      border-width: 0 20px 0 0;
    }
  }
  .selectBox-selected {
    border: 0 !important;
  }
  a {
    @include typography-body-text;
    line-height: 1;
    background: $color-white;
    color: $color-gray-on-light;
    text-transform: capitalize;
    margin: 10px 0;
    padding: 5px 20px;
    height: auto;
    @include breakpoint($landscape-up) {
      line-height: 1.1;
      background: $color-core-black;
      color: $color-core-dark-gray;
      padding: 0 30px;
      margin: 25px 0;
    }
    &:hover {
      color: $color-white;
    }
  }
}

// this is a temporary fix to hide global footer nodes from before the 2022 redesign
// because they can't be unplished until the footer redesign has been rolled out to all locales
// node 800 is the global footer contact menu
.block-nodeblock-800 {
  display: none;
}
// node 814 is the global footer copyright
.block-nodeblock-814 {
  display: none;
}
// node 1564 is the copyright specific to CA
.block-nodeblock-1564 {
  display: none;
}
// node 1380 is the copyright specific to ES
.block-nodeblock-1380 {
  display: none;
}
// node 6076 is the copyright specific to FR
.block-nodeblock-6076 {
  display: none;
}
// node 13074 is the copyright specific to ZA
.block-nodeblock-13074 {
  display: none;
}
// node 50 is the footer sticky offer
.block-nodeblock-50 {
  display: none;
}
